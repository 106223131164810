@tailwind base;
@tailwind components;
@tailwind utilities;





#vid {

}

iframe {
  margin:auto;
  justify: center;
  height:300px;
  width: 350px;
}
@media screen and (min-width: 640px) {
  iframe {
     height:280px;
     width: 400px;
  }

  #logoimage {
    height: 5.5em;
    
  }
}

nav {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

h1, #title {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}
a {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}
p {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}
.lexend-word {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

#lexend-wordnav {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: bolder;
  font-style: normal;
}

#lexend {
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
  }

  #poppins {
    font-family: "poppins";
    
  }


.main {
  font-family: Lexend;
}

#watchvid {
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
